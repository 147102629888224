import React from 'react';
import { RichText } from "prismic-reactjs";

export const Accordion = ({items, renderContent, className}) => (
  <ul className={`list-unstyled ${className}`}>
    {items.map((item, index) => (
      <div data-toggle="collapse" data-target={'#collapseItem' + index}
           aria-expanded="false" aria-controls={'collapseItem' + index}>
        <li className="mb15">
          <div className="border-bottom d-flex">
            <p className="accordion-name mb15 d-inline-block">
              <RichText render={item.title} />
            </p>
            <div className="ml-auto position-relative">
              <i className="glyphicon glyphicon-chevron-down"></i>
            </div>
          </div>
          <div className="collapse p20 mr15 " id={'collapseItem' + index}>
            {renderContent(item)}
          </div>
        </li>
      </div>
      ))}
  </ul>
)
