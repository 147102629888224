import React from "react"
import { RichText } from "../common/rich-text.component"
import { Section } from "../section.component"
import { Html } from "../common/html.component"

export class FormSlice extends React.PureComponent {
  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return false;
  }

  render() {
    const { slice, large } = this.props;
    const backgroundColor = slice.primary && slice.primary.bg_color
    const backgroundImage = slice.primary && slice.primary.bg_image
    return (
      <Section large={large} className={"form-slice"} backgroundImage={backgroundImage} backgroundColor={backgroundColor}>
        <div className="panel panel-default">
          <div className="panel-body">
            <RichText render={slice.primary.text}/>
            <div className="alert" role="alert" id="sendUsAlert" style={{ display: "none" }}>
            </div>
            <Html html={slice.primary.form_script}/>
          </div>
        </div>
      </Section>
    );
  }
}
