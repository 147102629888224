import React from "react"
import { RichText } from "../common/rich-text.component"
import { Section } from "../section.component"
import { PlainStructuredText } from "../common/plain-structured-text.component"

export const ImageWithTextList = ({ slice, large }) => {
  const backgroundColor = slice.primary && slice.primary.background_color
  const backgroundImage = slice.primary && slice.primary.background_image

  if (slice.label === "collapsable") {
    return (
      <Section className="resources-section" large={large} backgroundImage={backgroundImage} backgroundColor={backgroundColor}>
        <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
          {slice.fields.map((item, index) => (
            <div className="panel panel-default">
              <div className="panel-heading" role="tab" id="headingOne">
                <h4 className="panel-title">
                  <a className="accordion-toggle" role="button" data-toggle="collapse" data-parent="#accordion"
                     href={`#collapse${index}`} aria-expanded="true" aria-controls="collapseOne">
                    <PlainStructuredText structuredText={item.title} />
                  </a>
                </h4>
              </div>
              <div id={`collapse${index}`} className="panel-collapse collapse" role="tabpanel"
                   aria-labelledby="headingOne">
                <div className="panel-body">
                  <div className="resources-text">
                    <RichText render={item.text}/>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Section>
    )
  }
  return null
}
