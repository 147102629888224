import React from "react"

export class MapSlice extends React.Component {
  componentDidMount() {
   /* window.google.maps.event.addDomListener(window, 'load', init);
    var map, description, telephone, email, markericon, web, marker, link, iw;
    function init() {
      var mapOptions = {
        center: new window.google.maps.LatLng(40.703416, -74.1),
        zoom: 13,
        zoomControl: true,
        zoomControlOptions: {
          style: window.google.maps.ZoomControlStyle.SMALL,
        },
        disableDoubleClickZoom: true,
        mapTypeControl: false,
        scaleControl: false,
        scrollwheel: false,
        panControl: false,
        streetViewControl: false,
        draggable: true,
        overviewMapControl: false,
        overviewMapControlOptions: {
          opened: false,
        },
        mapTypeId: window.google.maps.MapTypeId.ROADMAP,
        styles: undefined,
      }
      var mapElement = document.getElementById('gmap');
      map = new window.google.maps.Map(mapElement, mapOptions);
      var locations = [
        ['TRT International', 'TRT International is an international freight forwarding company, established over 20 Years, fully licensed for US trucking, air and ocean freight shipping. We handle shipments to any point worldwide.', '+1 (973) 344-7100', 'info@usatrt.com', 'http://usatrt.com/', 40.7017683, -74.14146249999999, '/Content/img/trt-marker.png']
      ];
      for (var i = 0; i < locations.length; i++) {
        if (locations[i][1] == 'undefined') { description = ''; } else { description = locations[i][1]; }
        if (locations[i][2] == 'undefined') { telephone = ''; } else { telephone = locations[i][2]; }
        if (locations[i][3] == 'undefined') { email = ''; } else { email = locations[i][3]; }
        if (locations[i][4] == 'undefined') { web = ''; } else { web = locations[i][4]; }
        if (locations[i][7] == 'undefined') { markericon = ''; } else { markericon = locations[i][7]; }
        marker = new window.google.maps.Marker({
          icon: markericon,
          position: new window.google.maps.LatLng(locations[i][5], locations[i][6]),
          map: map,
          title: locations[i][0],
          desc: description,
          tel: telephone,
          email: email,
          web: web
        });
        link = ''; bindInfoWindow(marker, map, locations[i][0], description, telephone, email, web, link);
      }
      function bindInfoWindow(marker, map, title, desc, telephone, email, web, link) {
        var infoWindowVisible = (function () {
          var currentlyVisible = false;
          return function (visible) {
            if (visible !== undefined) {
              currentlyVisible = visible;
            }
            return currentlyVisible;
          };
        }());
        iw = new window.google.maps.InfoWindow();
        window.google.maps.event.addListener(marker, 'click', function () {
          if (infoWindowVisible()) {
            iw.close();
            infoWindowVisible(false);
          } else {
            var html = "<div style='color:#000;background-color:#fff;padding:5px;'><h4>" + title + "</h4><p>" + telephone + "<p><a href='mailto:" + email + "' >" + email + "<a></div>";
            iw = new window.google.maps.InfoWindow({ content: html });
            iw.open(map, marker);
            infoWindowVisible(true);
          }
        });
        window.google.maps.event.addListener(iw, 'closeclick', function () {
          infoWindowVisible(false);
        });
      }
    }*/
  }
  render() {

    return <div className="map">

    </div>
  }
}
