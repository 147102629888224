import React from "react"
import { Section } from "../section.component"
import { RichText } from "../common/rich-text.component"


export const TextSlice = ({ slice, large }) => {
  const backgroundColor = slice.primary && slice.primary.background_color;
  const backgroundImage = slice.primary && slice.primary.background_image;
  return (
    <Section backgroundColor={backgroundColor} large={large} backgroundImage={backgroundImage}>
      <div className={`text-slice-text ${slice.label}`}>
        <RichText render={slice.primary.text}/>
      </div>
    </Section>
  )
}
