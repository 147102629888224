import React, { useEffect, useRef } from "react"
import {v4} from "uuid"
import { Image } from "./common/image.component"
import $ from "jquery";

export const Preview = ({ image, videoUrl }) => {
  const uid = v4()
  const video = useRef(null);

  const onPlay = () => {
    if(video &&  video.current) {
      video.current.play();
    }
  }
  const onStop = () => {
    if(video &&  video.current) {
      video.current.pause();
    }
  }

  return (
    <>
      <a onClick={onPlay} className={videoUrl ? "" : `gallery-link embed-responsive embed-responsive-16by9`}
         data-toggle="modal" href={`#gallery-${uid}`}>
        <Image className="testimonials-img" image={image}/>
        {videoUrl ? <div className="play-button"/> : null}
      </a>
      <div onClick={onStop} className="modal fade" id={`gallery-${uid}`}>
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <button onClick={onStop} type="button" className="close" data-dismiss="modal"
                      aria-hidden="true">&times;</button>
              <h4 className="modal-title">&nbsp;</h4>
            </div>
            <div className="modal-body">
              {videoUrl
                ? (<video ref={video} controls src={videoUrl} width="100%"/>)
                : <Image image={image}/>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
