import React from "react"
import { RichText } from "../common/rich-text.component"
import { Section } from "../section.component"
import { CustomLink } from "../common/custom-link.component"

export const TextWithTitleSlice = ({ slice, large }) => {
  const backgroundColor = slice.primary && slice.primary.background_color
  const backgroundImage = slice.primary && slice.primary.background_image
  return (
    <Section backgroundColor={backgroundColor} large={large} backgroundImage={backgroundImage}>
      <div className="text-with-title">
        {(() => {
          if (slice.primary) {
            switch (slice.label) {
              case "two-cols":
                return (
                  <div className="row mb15">
                    <div className="col-sm-4">
                      <div className="mb30">
                        <RichText render={slice.primary.title}/>
                      </div>
                      <CustomLink className="more" link={slice.primary.link}>
                        {slice.primary.link_text}
                      </CustomLink>
                      <div className="clearfix"><br/></div>
                    </div>
                    <div className="col-sm-6">
                      <br/>
                      <RichText render={slice.primary.text}/>
                    </div>
                  </div>
                )
              default:
                return (
                  <div>
                    <div className="mb30">
                      <RichText render={slice.primary.title}/>
                    </div>
                    <RichText render={slice.primary.text}/>
                    <CustomLink className="more" link={slice.primary.link}>
                      {slice.primary.link_type}
                    </CustomLink>
                  </div>
                )
            }
          }
          return null
        })()}
        {slice.fields ? slice.fields.map(item => (
          <RichText render={item.text}/>
        )) : null}
      </div>
    </Section>
  )
}
