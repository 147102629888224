import React, { useState } from "react"
import { PrismicImage } from "./prismic-image.component"
import { LocalImage } from "./local-image.component"

const renderImage = (image, alt, className, style, onError) => {
  if (typeof image === "string" && !(/https?:/.test(image))) {
    return <LocalImage alt={alt} src={image} style={style} className={className}/>
  }
  if (typeof image === "object") {
    if (image.childImageSharp) {
      return <PrismicImage image={image} style={style} className={className}/>
    }
    if (image.url) {
      return (<div className={`gatsby-image-wrapper ${className || ''}`}>
        <img src={image.url} style={style} alt={image.alt} onError={onError}/>
      </div>)
    }
  }
  if (typeof image === "string") {
    if(alt === '!!!') {
      console.log('Render Image !!!!')
      console.log(image);
    }
    return (<div className={`gatsby-image-wrapper ${className || ''}`}>
      <img src={image} style={style} alt={alt} onError={onError}/>
    </div>)
  }
  return null
}

export const Image = ({ image, alt = "", className, style = {} }) => {
  const [errorImg, setErrorImg] = useState(null);

  const onError = () => {
    setErrorImg("no-photo-for-car.png");
  }
  if (!image) {
    return <span>{alt}</span>
  }
  return renderImage(errorImg || image, alt, className, style, onError)
}
