import React from "react"
import { Link } from "gatsby"
import moment from "moment";
import { PlainStructuredText } from "./common/plain-structured-text.component"
import { Image } from "./common/image.component"
import { linkResolver } from "../link-resolver"


export const Thumbnail = ({ post }) => {
  return (
    <div className="thumbnail-col">
      <Link className="thumbnail" to={linkResolver(post._meta, post.category)}>
        <Image className="thumbnail-img" image={post.image} />
        <div className="caption text-center">
          <small className="text-uppercase">
            <PlainStructuredText structuredText={post.category && post.category.title || ''} />
          </small>
          <h5>
            <PlainStructuredText structuredText={post.title} />
          </h5>
          <small>{moment(post._meta.firstPublicationDate).format("MMM Do YYYY")}</small>
        </div>
      </Link>
    </div>
  )
}
