import React from "react"
import { RichText } from "../common/rich-text.component"
import { Image } from "../common/image.component"
import { getLinkClass } from "../../utils/styles"
import { CustomLink } from "../common/custom-link.component"
import { Section } from "../section.component"


export const BlockWithImage = ({ slice, large }) => {
  const bgImage = slice.primary && slice.primary.bg_image
  const bgColor = slice.primary && slice.primary.bg_color
  const minHeight = slice.primary && slice.primary.min_height
  return (
    <Section className={`d-flex align-items-center ${slice.label}`}
             backgroundImage={bgImage} backgroundColor={bgColor} label={slice.label} large={large} minHeight={minHeight}>
      {(()=>{
        switch (slice.label) {
          case 'content-right-narrow':
          case 'content-right':
            return  (
              <div className="row align-items-center">
                <div className="col-xs-12 col-md-6">
                   { slice.primary.image !== null && <Image image={slice.primary.image}/> }
                </div>
                <div className="col-xs-12 col-md-6">
                  <div className="mb25">
                    <RichText render={slice.primary.title}/>
                  </div>
                  <RichText render={slice.primary.text}/>
                  <CustomLink
                    link={slice.primary.link}
                    className={getLinkClass(slice.primary.link_style, "link")}>
                    {slice.primary.link_text}
                  </CustomLink>
                </div>
              </div>
            )
          case 'content-right-bg':
            return (
              <div className="row d-flex flex-wrap">
                <div className="col-xs-12 col-md-6">
                    { slice.primary.image !== null && <Image image={slice.primary.image}/> }
                </div>
                <div className="col-xs-12 col-md-6">
                  <div className="mb25">
                    <RichText render={slice.primary.title}/>
                  </div>
                  <RichText render={slice.primary.text}/>
                  <CustomLink
                    link={slice.primary.link}
                    className={getLinkClass(slice.primary.link_style, "link")}>
                    {slice.primary.link_text}
                  </CustomLink>
                </div>
              </div>
            )
          case 'content-left-narrow':
          case 'content-left':
            return (
              <div className="row align-items-center">
                <div className="col-xs-12 col-md-6">
                  <div className="mb25">
                    <RichText render={slice.primary.title}/>
                  </div>
                  <RichText render={slice.primary.text}/>
                  <CustomLink
                    link={slice.primary.link}
                    className={getLinkClass(slice.primary.link_style, "link")}>
                    {slice.primary.link_text}
                  </CustomLink>
                </div>
                <div className="col-xs-12 col-md-6">
                  <Image image={slice.primary.image}/>
                </div>
              </div>
            )
          case 'content-left-bg':
            return (
              <div className="row d-flex flex-wrap">
                <div className="col-xs-12 col-md-6">
                  <div className="mb25">
                    <RichText render={slice.primary.title}/>
                  </div>
                  <RichText render={slice.primary.text}/>
                  <CustomLink
                    link={slice.primary.link}
                    className={getLinkClass(slice.primary.link_style, "link")}>
                    {slice.primary.link_text}
                  </CustomLink>
                </div>
                <div className="col-xs-12 col-md-6">
                  <Image image={slice.primary.image}/>
                </div>
              </div>
            )
          default:
            return (
              <div className="block-with-image-block text-center">
                  <div className="mb25">
                  <RichText render={slice.primary.title}/>
                  </div>
                  <RichText render={slice.primary.text}/>
                <div className="mx-auto mb-5">
                  { slice.primary.image !== null && <Image image={slice.primary.image}/> }
                </div>
                  <CustomLink
                    link={slice.primary.link}
                    className={getLinkClass(slice.primary.link_style, "link")}>
                    {slice.primary.link_text}
                  </CustomLink>
              </div>
            )
        }

      })()}

    </Section>
  )
}
