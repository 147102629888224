import React from "react"
import { TextSlice } from "./slices/text.slice"
import { TextWithTitleSlice } from "./slices/text-with-title.slice"
import { ImageWithTitleSlice } from "./slices/image-with-text.slice"
import { TwoColumnsSlice } from "./slices/two-clos.slice"
import { DefaultSlice } from "./slices/default.slice"
import { ContactsSlice } from "./slices/contacts.slice"
import { MapSlice } from "./slices/map.slice"
import { FormSlice } from "./slices/form.slice"
import { GallerySlice } from "./slices/gallery.slice"
import { ImageWithTextList } from "./slices/image-with-text-list.slice"
import { Features } from "./slices/features.slice"
import { BlockWithImage } from "./slices/block-with-image.slice"
import { ScriptSlice } from "./slices/script.slice"
import { ItemsCollection } from "./slices/items-collection.slice.js"
import { Pricing } from "./slices/pricing.slice"
import { MenuSlice } from "./slices/menu.slice"
import { Testimonials } from "./slices/testimonials.slice"
import {BlogPosts} from "./slices/blog-posts.slice";
import {VideoSlice} from "./slices/video.slice";
import { ShippingCalculator } from "./shiping-calculator.component"
import { ShippingCalculatorSlice } from "./slices/shipping-calculator"
import {ApplyTitleSlice} from "./slices/apply-title.slice";
import {ApplyFormSlice} from "./slices/apply-form.slice";
import {JobDescriptionShortSlice} from "./slices/job-description-short.slice";

export const Slices = ({ body, large }) => {
  if (body) {
    return body.map((slice, index) => {
      switch (slice.type) {
        case "text":
        case "rich_text":
          return <TextSlice slice={slice} large={large}/>
        case "text_with_title":
          return <TextWithTitleSlice slice={slice} large={large}/>
        case "image_with_text":
          return <ImageWithTitleSlice slice={slice} large={large}/>
        case "two_columns":
          return <TwoColumnsSlice slice={slice} large={large}/>
        case "contacts":
          return <ContactsSlice slice={slice} large={large}/>
        case "map":
          return <MapSlice slice={slice} large={large} />
        case "form":
          return <FormSlice slice={slice} large={large} />
        case "gallery":
          return <GallerySlice slice={slice} large={large} />
        case "text_with_title_list":
          return <ImageWithTextList slice={slice} large={large}/>
        case 'feature':
        case 'featute':
          return <Features slice={slice} large={large}/>
        case 'block_with_text_and_image':
          return <BlockWithImage slice={slice} large={large}/>
        case 'custom_script':
          return <ScriptSlice slice={slice}/>
        case 'items_collection':
          return <ItemsCollection slice={slice} large={large}/>
        case 'pricing_plans':
          return <Pricing slice={slice} large={large}/>
        case 'menu':
          return <MenuSlice slice={slice}/>
        case 'testimonials':
          return <Testimonials slice={slice} large={large} index={index}/>
        case 'blog_posts':
          return <BlogPosts slice={slice} index={index}/>
        case "video":
          return <VideoSlice slice={slice} large={large} />
        case 'shipping_calculator':
          return <ShippingCalculatorSlice slice={slice} large={large}/>
        case 'application_title':
          return <ApplyTitleSlice slice={slice} large={large}/>
        case 'apply_form':
          return <ApplyFormSlice slice={slice} large={large}/>
        case 'job_description_short':
          return <JobDescriptionShortSlice slice={slice} large={large}/>
        default:
          return <DefaultSlice slice={slice} large={large}/>
      }
    })
  }
  return null
}
