import React from "react"
import { Section } from "../section.component"
import { RichText } from "../common/rich-text.component"
import Video from "../common/video.component"

export const VideoSlice = ({slice, large}) => {

    const {primary, fields} = slice;
    const backgroundColor = primary && primary.bg_color
    const backgroundImage = primary && primary.bg_image
    const renderVideo = (videoUrl, videoThumbnail) =>  {
        if(/^https?:\/\/(www\.)?youtu/.test(videoUrl)) {
            return <iframe width="100%" src={videoUrl} />
        } else if(videoUrl) {
          return <Video src={videoUrl} thumbnail={videoThumbnail} />
        }
        return <span className="has-error">No Video</span>
     }
    return (
        <Section large={large} backgroundImage={backgroundImage} backgroundColor={backgroundColor}>
            <div className="text-center mb40">
                <RichText render={primary.title}/>
                <RichText render={primary.text}/>
            </div>
            <div className="video-frame">
                {fields.map(f => renderVideo(f.video_url, f.video_thumbnail))}
            </div>
        </Section>
    );
}
