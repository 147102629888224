export const select = {

  dropdownIndicator: (base) => ({
    ...base,
    paddingRight: 0
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: 'none'
  }),
  option: (base) => ({
    ...base,
    fontSize: '12px'
  }),
  singleValue: (base) => ({
    ...base,
    fontSize: '12px'
  }),
  placeholder: (base) => ({
    ...base,
    whiteSpace: 'nowrap'
  }),
}