import React from "react"
import {Image} from "../common/image.component";
import {RichText} from "../common/rich-text.component";
import {linkResolver} from "../../link-resolver";

export const JobDescriptionShortSlice = ({slice, large}) => {
    const primary = slice.primary;
    return (
        <section>
            <div className="d-flex container">
                <div className="row d-flex flex-wrap">
                    <div className="col-md-5 pt20 my-auto">
                        <Image image={primary.image} className="img-responsive one-column-image"/>
                    </div>
                    <div className="d-flex flex-column col-md-7 my-auto">
                        <RichText className="mb15" render={primary.title}/>
                        <div className="d-flex" style={{flexGrow: 1}}/>
                        <RichText className="mb25" render={primary.job_description}/>
                        <div className="d-flex" style={{flexGrow: 1}}/>
                        <div className="d-flex" style={{gap: "10px", flexWrap: "wrap"}}>
                            {slice.fields.map((item, index) => {
                                return <a
                                    key={index}
                                    className="btn btn-primary btn-xlg text-uppercase bold"
                                    href={linkResolver(item.button_link._meta)}>{item.button_text}</a>
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
