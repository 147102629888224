import React from "react"
import { Accordion } from "./accordion.component"
import { Image } from "./image.component"
import { CustomLink } from "./custom-link.component"
import { getLinkClass } from "../../utils/styles"
import { RichText } from "./rich-text.component"

export const Collection = ({ type, items }) => {
  switch (type) {
    case "accordion":
      return (
        <Accordion items={items} renderContent={item => {
          return (
            <div className="d-flex flex-wrap flex-sm-nowrap features-slice-accordion">
              <div>
                <Image className="w-sm-100 w-300 mb15" alt="img" image={item.image}/>
              </div>
              <div className="ml15 ml-sm-0">
                <RichText render={item.text}/>
                <div>
                  <CustomLink
                    link={item.link}
                    className={`btn-sm ${getLinkClass(item.link_style, "link")}`}>
                    {item.link_text}
                  </CustomLink>
                </div>
              </div>
            </div>
          )
        }}/>
      )
    case "list-numbered":
      return (
        <div>
          {items.map((item, index) => {
            return (
              <div className="d-flex flex-wrap flex-sm-nowrap mb25">
                <div className="list-numbered-number-block">
                  <div className="number">{index + 1}</div>
                </div>
                <div className="ml0 ml-md-5 mw-900">
                  <div className="list-numbered-title">
                    <RichText render={item.title}/>
                  </div>
                  <RichText render={item.text}/>
                  <div>
                    <CustomLink
                      link={item.link}
                      className={getLinkClass(item.link_style, "link")}>
                      {item.link_text}
                    </CustomLink>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      )
    case "list-image":
      return (
        <div>
          {items.map((item) => {
            return (
              <div className="d-flex flex-wrap flex-sm-nowrap mb40">
                <div className="list-image-img mx-auto mx-md-0 mb25 mb-md-0">
                  <Image image={item.image}/>
                </div>
                <div className="ml-md-5 mb25 mw-900">
                  <RichText render={item.title}/>
                  <RichText render={item.text}/>
                  <div>
                    <CustomLink
                      link={item.link}
                      className={getLinkClass(item.link_style, "link")}>
                      {item.link_text}
                    </CustomLink>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      )
    case "list-process":
      return (
        <div className="mw-900 mx-auto">
          {items.map((item, index) => {
            return (
              <div className="d-flex mb40 position-relative list-process-item">
                <div className="process-circle"></div>
                <div className="ml50 list-process-text">
                  <RichText render={item.title}/>
                  <RichText render={item.text}/>
                  <div>
                    <CustomLink
                      link={item.link}
                      className={getLinkClass(item.link_style, "link")}>
                      {item.link_text}
                    </CustomLink>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      )
    case "col-1-detailed":
      return (
        <div>
          {items.map(item => {
            return (
              <div className="d-flex mb70 col-1-mixed align-items-center">
                <div className="col-1-image">
                  <Image image={item.image}/>
                </div>
                <div className="col-1-content">
                  <div className="mb40">
                    <RichText render={item.title}/>
                  </div>
                  <RichText className="col-1-text" render={item.text}/>
                  <CustomLink
                    link={item.link}
                    className={getLinkClass(item.link_style, "link")}>
                    {item.link_text}
                  </CustomLink>
                </div>
              </div>
            )
          })}
        </div>
      )
    case "col-2-detailed-small-image":
      return (
        <div className="row d-flex flex-wrap col-2-small-items">
          {items.map(item => {
            return (
              <div className="col-xs-12 d-flex col-lg-6">
                <div className="col-2-small-image mx-auto">
                  <Image image={item.image}/>
                </div>
                <div>
                  <div className="ml15">
                    <RichText render={item.title}/>
                    <RichText render={item.text}/>
                  </div>
                  {item.link_text ? (<div className="mt30 text-center">
                      <CustomLink
                        link={item.link}
                        className={getLinkClass(item.link_style, "link")}>
                        {item.link_text}
                      </CustomLink>
                    </div>
                  ) : null}
                </div>
              </div>
            )
          })}
        </div>
      )
    case "col-3-detailed":
    case "col-3-detailed-small-image":
      return (
        <div className={`row d-flex flex-wrap ${type}`}>
          {items.map(item => {
            return (
              <div className="col-xs-12 col-md-4 col-3-item">
                {item.image ? (
                  <div className="col-3-image mb25">
                    {type === "col-3-detailed" ?
                      <Image image={item.image}/>
                      : <Image image={item.image} style={{
                        width: item.image.dimensions.width / 2 + "px",
                        height: item.image.dimensions.height / 2 + "px",
                      }}/>
                    }
                  </div>
                ) : null
                }
                <div className="text-center">
                  <RichText render={item.title}/>
                  <RichText render={item.text}/>
                </div>
                {item.link_text ? (<div className="mt30 text-center">
                  <CustomLink
                    link={item.link}
                    className={getLinkClass(item.link_style, "link")}>
                    {item.link_text}
                  </CustomLink>
                </div>) : null}
              </div>
            )
          })}
        </div>
      )
    case "col-4-detailed":
      return (
        <div className="row d-flex flex-wrap justify-content-center">
          {items.map(item => {
            return (
              <div className="col-xs-6 col-md-3 col-4-item">
                {item.image ? (
                  <div className="col-4-image text-center">
                    <Image image={item.image} style={{
                      width: item.image.dimensions.width / 2 + "px",
                      height: item.image.dimensions.height / 2 + "px",
                    }}/>
                  </div>
                ) : null}
                <div className="text-center">
                  <RichText render={item.title}/>
                  <RichText render={item.text}/>
                </div>
                {item.link_text ? (<div className="mt30 text-center">
                  <CustomLink
                    link={item.link}
                    className={getLinkClass(item.link_style, "link")}>
                    {item.link_text}
                  </CustomLink>
                </div>) : null}
              </div>
            )
          })}
        </div>
      )
    case "col-4-detailed-small-image":
      return (
        <div className="row">
          {items.map(item => {
            return (
              <div className="d-flex col-xs-12 col-md-6 col-lg-3 mb50">
                <div className="mx-auto mb40 col-4-small">
                  <Image image={item.image}/>
                </div>
                <div className="text-center">
                  <RichText render={item.title}/>
                  <RichText render={item.text}/>
                </div>
                {item.link_text ? (<div className="mt30 text-center">
                  <CustomLink
                    link={item.link}
                    className={getLinkClass(item.link_style, "link")}>
                    {item.link_text}
                  </CustomLink>
                </div>) : null}
              </div>
            )
          })}
        </div>
      )
    case "col-5-detailed":
      return (
        <div className="row d-flex flex-wrap justify-content-center">
          {items.map(item => {
            return (
              <div className="col-xs-4 col-lg-2-4 col-5-item">
                {item.image ? (
                  <div className="col-5-image text-center">
                    <Image image={item.image} style={{
                      width: item.image.dimensions.width / 2 + "px",
                      height: item.image.dimensions.height / 2 + "px",
                    }}/>
                  </div>
                ) : null
                }
                <div className="text-center">
                  <RichText render={item.title}/>
                  <RichText render={item.text}/>
                </div>
                {item.link_text ? (<div className="mt30 text-center">
                  <CustomLink
                    link={item.link}
                    className={getLinkClass(item.link_style, "link")}>
                    {item.link_text}
                  </CustomLink>
                </div>) : null}
              </div>
            )
          })}
        </div>
      )
    case "block-3-detailed":
      return (
        <div className="row d-flex flex-wrap justify-content-center">
          {items.map(item => {
            return (
              <div className="col-12 col-lg-4 d-flex flex-column">
                <div className="block-3-wrapper">
                  <div className="line bg-primary"/>
                  <div className="mx-auto block-3-image">
                    <Image image={item.image}/>
                  </div>
                  <div className="text-content">
                    <div className="text-center mb15">
                      <RichText render={item.title}/>
                    </div>
                    <RichText render={item.text}/>
                  </div>
                  {item.link_text ? (<div className="mt30 text-center">
                    <CustomLink
                      link={item.link}
                      className={getLinkClass(item.link_style, "link")}>
                      {item.link_text}
                    </CustomLink>
                  </div>) : null}
                </div>
              </div>
            )
          })}
        </div>
      )
    case "carousel":
      return (
        <div id="feature-carousel" className="carousel feature-carousel slide" data-ride="carousel">
          <ol className="carousel-indicators">
            {items.map((item, index) => {
              return (
                <li data-target="#feature-carousel" data-slide-to={index}
                    className={index === 0 ? "active" : ""}/>
              )
            })}
          </ol>
          <div className="carousel-inner">
            {items.map((item, index) => {
              return (
                <div className={index === 0 ? "item active" : "item"}>
                  <Image image={item.image}/>
                  <div className="slider__wrap"></div>
                  <div className="carousel-caption">
                    <RichText render={item.title}/>
                    <RichText render={item.text}/>
                  </div>
                </div>
              )
            })}
          </div>
          <a className="left carousel-control" href="#feature-carousel" role="button" data-slide="prev">
            <span className="glyphicon glyphicon-chevron-left"/>
          </a>
          <a className="right carousel-control" href="#feature-carousel" role="button" data-slide="next">
            <span className="glyphicon glyphicon-chevron-right"/>
          </a>
        </div>
      )
    case "col-4-image-link":
      return (
        <div className="row d-flex mt50 flex-wrap justify-content-center">
          {items.map(item => {
            return (
              <div className="col-xs-3 col-4-item">
                {item.image ? (
                  <div className="col-4-image text-center">
                    <CustomLink link={item.link}>
                      <Image image={item.image} style={{
                        width: item.image.dimensions.width / 2 + "px",
                        height: item.image.dimensions.height / 2 + "px",
                      }}/>
                    </CustomLink>
                  </div>
                ) : null}
              </div>
            )
          })}
        </div>
      )
    case "col-4-detailed-arrow":
      return (
        <div className="row d-flex flex-wrap justify-content-center">
          {items.map(item => {
            return (
              <div className="col-sm-6 col-xs-12 col-md-3 col-4-item">
                {item.image ? (
                  <div className="col-4-image text-center">
                    <Image image={item.image} style={{
                      width: item.image.dimensions.width / 2 + "px",
                      height: item.image.dimensions.height / 2 + "px",
                    }}/>
                  </div>
                ) : null}
                <div className="text-center">
                  <RichText render={item.title}/>
                  <RichText render={item.text}/>
                </div>
                {item.link_text ? (<div className="mt30 text-center">
                  <CustomLink
                    link={item.link}
                    className={getLinkClass(item.link_style, "link")}>
                    {item.link_text}
                  </CustomLink>
                </div>) : null}
                <div className="double-chevron-wrapper">
                </div>
              </div>
            )
          })}
            </div>
            )
            default:
            return null
          }
          }
