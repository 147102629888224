import React from "react"
import { Background } from "./common/background-image.component"
import { isDark } from "../utils/styles"

export const Section = ({backgroundImage, backgroundColor, className, large, children, minHeight, label}) => {
  const dark = isDark(backgroundColor, backgroundImage)
  const _class = large ? 'col-md-10 col-md-offset-1' : (label && label.indexOf('narrow') > -1 ? 'col-md-5 col-md-offset-4' : 'col-md-8 col-md-offset-2')
  return (
    <Background
      className={`section ${dark ? 'section-dark' : 'section-light'} ${className}`}
      image={backgroundImage}
      style={{
        minHeight: (minHeight + "px"),
        backgroundColor: (backgroundColor
          ? backgroundColor
          : "transparent"),
      }}
    >
      {backgroundImage ? (
        <div className="slider__wrap"/>
      ) : null
      }
      <div className="container">
        <div className="row">
          <div className={`col-sm-10 col-sm-offset-1 ${_class}`}>
            {children}
          </div>
        </div>
      </div>
    </Background>
  )
}
