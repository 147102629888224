import React from "react"
import { Section } from "../section.component"
import { RichText } from "../common/rich-text.component"
import { Image } from "../common/image.component"

export const ImageWithTitleSlice = ({ slice, large }) => {
  const backgroundColor = slice.primary && slice.primary.background_color
  const backgroundImage = slice.primary && slice.primary.background_image
  if (slice.label === "one-col-with-bg") {
    return (
      <div>
        {
          slice.primary.text && slice.primary.text.html ? (
            <Section large={large} backgroundColor={backgroundColor} backgroundImage={backgroundImage}>
              <div className="image_with_text">
                <RichText render={slice.primary.text}/>
              </div>
            </Section>
          ) : null}
        {slice.fields.map((item, index) => {
          if (index % 2 === 0) {
            return (
              <Section large={large} backgroundColor="#fff">
                <div className="row">
                  <div className="col-sm-4">
                    <Image image={item.image} className="img-responsive"/>
                    <br/>
                  </div>
                  <div className="col-sm-8">
                    <RichText render={item.text}/>
                  </div>
                </div>
              </Section>
            )
          } else {
            return (
              <Section large={large}>
                <div className="row">
                  <div className="col-sm-8">
                    <RichText render={item.text}/>
                  </div>
                  <div className="col-sm-4">
                    <Image image={item.image} className="img-responsive"/>
                    <br/>
                  </div>
                </div>
              </Section>
            )
          }
        })}
      </div>
    )
  }
  return (
    <Section large={large} backgroundColor={backgroundColor} backgroundImage={backgroundImage}>
      <div className="image_with_text">
        <div className="image-with-text-header">
          <RichText render={slice.primary.text}/>
        </div>

        {(() => {
          switch (slice.label) {
            case "four-cols":
              return (
                <div className="row four-cols">
                  {slice.fields.map(item => (
                    <div className="col-xs-6 col-sm-4 col-md-3 bold h115 mb40">
                      <div style={{ width: "83px" }}>
                        <Image image={item.image}/>
                      </div>
                      <br/>
                      <div className="four-cols-text">

                      </div>
                      <RichText render={item.text}/>
                    </div>
                  ))}
                </div>
              )
            case "two-cols":
              return (
                <div className="row-services">
                  {slice.fields.map(item => (
                    <div className="col-services two-cols-cell">
                      <div>
                        <div className="log-icon-wrapper" style={{ width: "110px" }}>
                          <Image image={item.image}/>
                        </div>
                        <RichText render={item.text}/>
                      </div>
                    </div>
                  ))}
                </div>
              )
            case "three-cols":
              return (
                <div className="three-cols">
                  <div className="panel panel-primary">
                    <div className="row-custom-tailored">
                          {
                            slice.fields.map(item => (
                              <div className="col-custom-tailored">
                                <div className="media">
                                  <div className="media-left" style={{ minWidth: "30px" }}>
                                    <Image image={item.image}/></div>
                                  <div className="media-body">
                                    <RichText render={item.text}/>
                                  </div>
                                </div>
                              </div>
                            ))
                          }
                    </div>
                  </div>
                </div>
              )
            default:
              return (
                <div>
                  {slice.fields.map((item, index) => {
                    if (index % 2 === 0) {
                      return (
                        <div className="row">
                          <div className="col-sm-4">
                            <Image image={item.image} className="img-responsive one-column-image"/>
                            <br/>
                          </div>
                          <div className="col-sm-8">
                            <RichText render={item.text}/>
                          </div>
                        </div>
                      )
                    } else {
                      return (
                        <div className="row">
                          <div className="col-sm-8">
                            <RichText render={item.text}/>
                          </div>
                          <div className="col-sm-4">
                            <Image image={item.image} className="img-responsive"/>
                            <br/>
                          </div>
                        </div>
                      )
                    }
                  })}
                </div>
              )
          }
        })()}
      </div>
    </Section>
  )
}
