import React from "react"
import { RichText } from "../common/rich-text.component"
import { Section } from "../section.component"
import { Collection } from "../common/collection.component"



export const Features = ({ slice, large }) => {

  const { primary, fields, label } = slice
  const backgroundColor = primary && primary.bg_color
  const backgroundImage = primary && primary.bg_image
  return (
    <Section
      className="position-relative"
      backgroundImage={backgroundImage}
      backgroundColor={backgroundColor}
      large={large}>
      <div className={`features-slice ${label ? "features-slice-" + label : ""}`}>
        <div className="text-center mb30">
          <RichText render={primary.title}/>
          <RichText render={primary.text}/>
        </div>
          <Collection items={fields} type={label}/>
      </div>
    </Section>
  )
}



