import React from "react"
import {v4} from "uuid";
import { Section } from "../section.component"
import { RichText } from "../common/rich-text.component"
import { Image } from "../common/image.component"
import $ from "jquery"
import SwiperCore, {EffectCoverflow, Pagination, Navigation, Autoplay} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';


SwiperCore.use([EffectCoverflow, Pagination, Navigation,  Autoplay]);

export class GallerySlice extends React.PureComponent {
   constructor(props) {
     super(props);
     this.id = v4();
   }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.slice.label === 'carousel') {
      const carousel = $(".gallery-carousel")
      carousel.owlCarousel({
        items: 3,
        itemsTablet: 3,
        itemsMobile: 1,
      })
    }
  }

  componentDidMount(prevProps, prevState, snapshot) {
    if(this.props.slice.label === 'carousel') {
      const carousel = $(".gallery-carousel")
      carousel.owlCarousel({
        items: 3,
        itemsTablet: 3,
        itemsMobile: 1,
      })
    }
  }

  componentWillMount() {
    console.log("component will mount")
  }

  render() {
    const { slice, large } = this.props
    const bg_color = slice.primary && slice.primary.bg_color
    const bg_image = slice.primary && slice.primary.bg_image
    const { label } = slice
    return (
      <Section large={large} backgroundImage={bg_image} backgroundColor={bg_color}>
        <div className="gallery-header">
          <RichText render={slice.primary.text}/>
        </div>
        {
          (() => {
            switch(label) {
              case 'carousel':
                return (<div>
                  <div className="gallery-carousel">
                    {
                      slice.fields.map((item, index) => (
                        <div className="item">
                          <a className="gallery-link embed-responsive embed-responsive-16by9" data-toggle="modal"
                             href={`#gallery-${this.id}-${index}`}>
                            <Image image={item.image}/>
                          </a>
                        </div>
                      ))
                    }
                  </div>
                </div>);
              case 'swiper':
                return (
                  <Swiper
                    effect="coverflow"
                    spaceBetween={10}
                    slidesPerView={3}
                    coverflowEffect={{
                      rotate: 50,
                      stretch: 0,
                      depth: 100,
                      modifier: 1,
                      slideShadows: true
                    }}
                    navigation
                    pagination={{ clickable: true }}
                    autoplay = {{delay: 2000}}
                  >
                    {slice.fields.map((item, index) => (
                      <SwiperSlide>
                        <a className="gallery-link" data-toggle="modal"
                           href={`#gallery-${this.id}-${index}`}>
                          <Image image={item.image}/>
                        </a>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                )
              default:
                return (<div className="row">
                  {slice.fields.map((item, index) => {
                    return (
                      <div className="col-sm-4">
                        <a className="gallery-link embed-responsive embed-responsive-16by9" data-toggle="modal"
                           href={`#gallery-${this.id}-${index}`}>
                          <Image image={item.image}/>
                        </a>
                      </div>
                    )
                  })}
                </div>);
            }
          })()
        }
        {
          slice.fields.map((item, index) => (
            <div className="modal fade" id={`gallery-${this.id}-${index}`}>
              <div className="modal-dialog modal-lg">
                <div className="modal-content">
                  <div className="modal-header">
                    <button type="button" className="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                    <h4 className="modal-title">&nbsp;</h4>
                  </div>
                  <div className="modal-body">
                    <Image image={item.image}/>
                  </div>
                </div>
              </div>
            </div>
          ))
        }
      </Section>
    )
  }
}
