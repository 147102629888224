import React from "react"
import { Section } from "../common/section.component"
import { getLinkClass } from "../../utils/styles"
import { CustomLink } from "../common/custom-link.component"
import { RichText } from "../common/rich-text.component"

export const Pricing = ({ slice }) => {
  const { primary, fields, label } = slice
  const backgroundColor = primary && primary.bg_color
  const backgroundImage = primary && primary.bg_image
  return (
    <Section backgroundImage={backgroundImage} backgroundColor={backgroundColor}>
      <div className={`features-slice ${label ? "features-slice-" + label : ""}`}>
        {primary.title || primary.text ? (
          <div className="container text-center mb50">
          <RichText render={primary.title}/>
          <RichText render={primary.text}/>
        </div>) : null}

        <div className="d-flex flex-wrap justify-content-center container">
        {
          fields.map(plan => {
            return (
              <div className="pricing-slice-card-col">
                  <div className="text-center">
                    <RichText render={plan.plan_name} className="pricing-slice-card-header"/>
                  </div>
                  <div className="pricing-slice-card-body px15">
                    <div className="text-center">
                      <div className="mt10 mb25 pricing-slice-plan">
                        {plan.plan_price}
                        { plan.plan_free ? null : (
                          <small
                            className="font-16">
                            {plan.price_units}
                          </small>
                        )
                        }
                      </div>
                      <CustomLink
                        link={plan.link}
                        className={`btn btn-sm mb25 ${getLinkClass(plan.link_style, "link")}`}>
                        {plan.link_text}
                      </CustomLink>
                      <RichText render={plan.plan_features} className="plan-features text-left"/>
                    </div>
                  </div>
              </div>
          )
          })
        }
        </div>
      </div>
    </Section>
        )
}
