import React from "react"
import {RichText} from "../common/rich-text.component"
import {Section} from "../section.component"
import {Image} from "../common/image.component"
import {CustomLink} from "../common/custom-link.component"
import {getLinkClass} from "../../utils/styles"
import {withPreview} from "@prismicio/gatsby-source-prismic-graphql";
import {graphql, Link, StaticQuery} from "gatsby";
import {linkFragment, linkResolver} from "../../link-resolver";
import {convertRichTextToPlain, reduceHeaderSize} from "../../utils/text";
import {Thumbnail} from "../thumbnail.blog.component";
import {Pagination} from "../common/pagination.component";
import {getLangPrefix} from "../../utils/langs";


import {PlainStructuredText} from "../common/plain-structured-text.component";

const query = graphql`
    query blogPostsBlock {
        prismic {
            allBlogPages {
                edges {
                    node {
                        _meta {
                            firstPublicationDate
                            lang
                            type
                            uid
                        }
                        is_featured_article
                        title
                        page_title
                        preview_image
                        image
                        category {
                            ... on PRISMIC_BlogCategory {
                                _meta {
                                    lang
                                    type
                                    uid
                                }
                                title
                            }
                        }
                    }
                }
            }
        }
    }
`

export const BlogPosts = ({slice, index}) => {

    const {primary, label} = slice
    const backgroundColor = primary && primary.bg_color
    const backgroundImage = primary && primary.bg_image
    return (
        <Section backgroundImage={backgroundImage} backgroundColor={backgroundColor} large={true}>
            <div className={`blog-posts-slice ${label ? "blog-posts-slice-" + label : ""}`}>
                <div className="mb40">
                    <div className="text-center">
                        <RichText render={primary.title}/>
                    </div>
                        <RichText render={primary.text}/>
                </div>
                <StaticQuery
                    query={query}
                    render={
                        withPreview(data => {
                            const posts = data.prismic.allBlogPages.edges.filter(node => {
                                const item = node.node;
                                return item.is_featured_article === primary.featured
                                    && ((!primary.category && !item.category)
                                        || (primary.category && item.category && primary.category._meta.lang === item.category._meta.lang
                                            && primary.category._meta.uid === item.category._meta.uid));
                            }).slice(0, primary.number_of_posts);
                            return (
                                <div className="d-flex flex-wrap">
                                    {(posts &&
                                        posts.map((item, index) => {
                                            const post = item.node
                                            return (
                                                <div className="col-md-4">
                                                    <div className="blog-posts-slice-thumbnail">
                                                        <div className="">
                                                            <div className="thumbnail">
                                                                <Image className="thumbnail-img" image={post.image}/>
                                                                <div className="caption text-left">
                                                                    <small className="text-uppercase">
                                                                        <PlainStructuredText
                                                                            structuredText={post.category && post.category.title || ''}/>
                                                                    </small>
                                                                    <h5>
                                                                        <PlainStructuredText
                                                                            structuredText={post.title}/>
                                                                    </h5>
                                                                </div>
                                                                <Link className="link" to={linkResolver(post._meta, post.category)}>Read More ></Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })) ||
                                    null}

                                </div>
                            )
                        }, query, [linkFragment])
                    }/>
            </div>
        </Section>
    )
}



