import React from "react"
import {RichText} from "../common/rich-text.component";
import {Image} from "../common/image.component";

export const ApplyTitleSlice = ({slice, large}) => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-sm-10 col-sm-offset-1 col-md-10 col-md-offset-1">
                    <div className="row">
                        <div className="col-xs-12 col-md-6">
                            <div className="mb25">
                                <RichText render={slice.primary.title}/>
                            </div>
                            <RichText render={slice.primary.text}/>
                            <div className="mb25 btn btn-primary text-uppercase bold"
                                 onClick={() => {document.getElementById('EmploymentForm').scrollIntoView()}}
                                >
                                {slice.primary.apply_button_text}
                            </div>
                            <a href={`tel:${slice.primary.phone}`} className="ml15 mb25 btn btn-primary text-uppercase bold">
                                <img className="mr15" src="https://images.prismic.io/usatrt/2907380b-dae3-4ff2-a276-fe062c238cbc_i-phone-30px.png?auto=compress,format"
                                     style={{width: "15px", height: "15px"}} />
                                {slice.primary.phone}
                            </a>
                        </div>
                        <div className="col-xs-12 col-md-6">
                            <Image image={slice.primary.image}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
