import React from 'react';
import BackgroundImage from 'gatsby-background-image';
import PropTypes from 'prop-types';

export const Background = ({image, className, style, children}) => {
    // if(style && style.backgroundColor) {
    //     style.backgroundBlendMode = "overlay";
    // }
    if(!image) {
        return (<div className={className} style={style}>
            {children}
        </div>);
    }
    if (image.url) {
        return (<div className={className} style={{...style, backgroundImage: `url(${image.url})`}}>
            {children}
        </div>);
    }
    if (image.childImageSharp) {
        return (<BackgroundImage className={className} style={style} fluid={image.childImageSharp.fluid}>
            {children}
        </BackgroundImage>);
    }
    if (typeof image === 'string') {
        return (<div className={className} style={{...style, backgroundImage: `url(${image})`}}>
            {children}
        </div>);
    }
    return <div style={style} className={className}>{children}</div>;
};

Background.propTypes = {
    className: PropTypes.string,
    children: PropTypes.element,
    image: PropTypes.oneOfType([
        PropTypes.shape({
            url: PropTypes.string,
            localFile: PropTypes.shape({
                childImageSharp: PropTypes.shape({
                    fluid: PropTypes.any
                })
            })
        }),
        PropTypes.string
    ])
};
