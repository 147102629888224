import React from "react"
import {CustomLink} from "./custom-link.component"
import {getLinkClass} from "../../utils/styles"
import {Image} from "./image.component";

export const LinksRow = ({links, className, title}) => {
    return (
        <ul className={`d-flex flex-wrap ${className}`}>
            <div>{title}</div>
            {links.map(item => (
                <li className="links-row-list">
                    <CustomLink
                        link={item.link}
                        activeClassName="active"
                        className={getLinkClass(item.link_style, 'nav-link')}>
                        {item.icon ? <Image image={item.icon}/> : null}
                        {item.link_text}
                    </CustomLink>
                </li>
            ))}
        </ul>
    )
}
