import React from "react"
import {RichText} from "../common/rich-text.component";
import {Html} from "../common/html.component";

export const ApplyFormSlice = ({slice, large}) => {
    console.log(slice);
    return (
        <div className="container pb50">
            <div className="row">
                <div className="col-sm-10 col-sm-offset-1 col-md-10 col-md-offset-1">
                    <div className="row mt50">
                        <div className="col-md-12">
                            <RichText render={slice.primary.main_text}/>
                        </div>
                        <div className="col-md-6 mt-4">
                            <RichText render={slice.primary.left_column}/>
                        </div>
                        <div className="col-md-6 mt-4" id="apply-form">
                            <Html html={slice.primary.form_script}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
