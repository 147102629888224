import React from "react"
import { Section } from "../section.component"
import { Image } from "../common/image.component"
import { RichText } from "../common/rich-text.component"

export const DefaultSlice = ({ slice, large }) => {
  const backgroundColor = slice.primary && slice.primary.background_color
  const backgroundImage = slice.primary && slice.primary.background_image
  return (
    <Section large={large} backgroundImage={backgroundImage} backgroundColor={backgroundColor}>
      <div className="default-slice">
        <Image image={slice.primary && slice.primary.image}/>
        <RichText render={slice.primary && slice.primary.text}/>
        {slice.fields && slice.fields.map(item => (
          <div>
            <Image image={item.image}/>
            <RichText content={item.text}/>
          </div>
        ))}
      </div>
    </Section>
  )
}
