import React from "react"
import PropTypes from "prop-types";

export class Script extends React.Component {
  constructor(props) {
    super(props)

    this.elRef = React.createRef();
  }

  componentDidMount() {
    const {scriptUrl}  = this.props;
    if (scriptUrl && this.elRef && this.elRef.current) {
      this.elRef.current.src = scriptUrl;
    }
  }

  render() {
    return <script ref={this.elRef}>
    </script>
  }
}

Script.propTypes = {
  scriptUrl: PropTypes.string
}
