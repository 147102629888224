import React from "react"
import { RichText } from "../common/rich-text.component"
import { Section } from "../section.component"
import { Html } from "../common/html.component"

export const TwoColumnsSlice = ({ slice, large }) => {
  const backgroundColor = slice.primary && slice.primary.background_color
  const backgroundImage = slice.primary && slice.primary.background_image
  return (
    <Section backgroundImage={backgroundImage} large={large} backgroundColor={backgroundColor}>
      <div className="two-columns">
        {slice.primary ? (<RichText content={slice.primary.text}/>) : null}
        {(() => {
          switch (slice.label) {
            case "panels":
              return (
                <div className="row">
                  {slice.fields.map(item => (
                    <div className="col-sm-6">
                      <div className="panel panel-default"
                           style={item.border_color ? {
                             borderWidth: "3px",
                             borderColor: item.border_color,
                           } : {}}>
                        <div className="panel-body">
                          <Html html={item.html} />
                          <div className="send-request">
                            <RichText render={item.text}/>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )
            default:
              return (
                <div className="row">
                  {slice.fields.map(item => (
                    <div className="col-sm-6">
                      <Html html={item.html} />
                      <RichText render={item.text}/>
                    </div>
                  ))}
                </div>
              )
          }
        })()}
      </div>
    </Section>
  )
}
