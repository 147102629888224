import {Section} from "../section.component"
import React from "react"
import {RichText} from "../common/rich-text.component"

export const ContactsSlice = ({slice, large}) => {
    const backgroundColor = slice.primary && slice.primary.bg_color
    const backgroundImage = slice.primary && slice.primary.bg_image
    return (
        <Section className="section-contacts-slice" large={large} backgroundImage={backgroundImage} backgroundColor={backgroundColor}>
            {slice.primary.title ? (<RichText render={slice.primary.title}/>) : null}
            {slice.primary.text ? (<RichText render={slice.primary.text}/>) : null}
            {slice.fields.map(({description, address, phone, fax, email}) => {
                return (
                    <div>
                        {description ? ( <div className="">
                            <RichText render={description}/>
                        </div>) : null}
                        <div className="row">
                            {address ? (<div className="col-md-4">
                                <RichText render={address}/>
                            </div>) : null}
                            <div className="col-md-4">
                                <p>
                                    {phone ? (<span>{phone}<br/></span>) : null}
                                    {fax ? (<span>{fax}</span>) : null}
                                </p>
                            </div>
                            {email ? (<div className="col-md-4">
                                  <p><a href={`mailto:${email}`}>{email}</a></p>
                              </div>
                            ) : null}
                        </div>
                    </div>
                )
            })}
        </Section>
    )
}
