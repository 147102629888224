import React from "react"
import {RichText} from "../common/rich-text.component"
import {Section} from "../section.component"
import {Image} from "../common/image.component"
import {CustomLink} from "../common/custom-link.component"
import {getLinkClass} from "../../utils/styles"
import { Preview } from "../preview.component"


export const Testimonials = ({slice, large, index}) => {

    const {primary, fields, label} = slice
    const backgroundColor = primary && primary.bg_color
    const backgroundImage = primary && primary.bg_image
    return (
        <Section backgroundImage={backgroundImage} backgroundColor={backgroundColor} large={true}>
            <div className={`testimonials-slice ${label ? "testimonials-slice-" + label : ""}`}>
                <div className="text-center mb40">
                    <RichText render={primary.title}/>
                    <RichText render={primary.text}/>
                </div>
                {(() => {switch(label) {
                    case 'carousel':
                    return (<div id="testimonials-carousel" className="carousel testimonials-carousel slide"  data-ride="carousel">
                        <ol className="carousel-indicators">
                            {fields.map((item, index) => {
                                return (
                                    <li data-target="#testimonials-carousel" data-slide-to={index}
                                        className={index === 0 ? "active" : ""}/>
                                )
                            })}
                        </ol>
                        <div className="carousel-inner">
                            {fields.map((item, index) => {
                                return (
                                    <div className={index === 0 ? "item active" : "item"}>
                                        <Image image={item.image}/>
                                        <div className="slider__wrap"></div>
                                        <div className="carousel-caption">
                                            <div>
                                            <Image className="client-photo" image={item.client_photo}/>
                                            <>
                                                <p className="client-name">{item.client_name}</p>
                                                <div className="d-flex mb15 rates-wrapper">
                                                    {
                                                        new Array(5).fill(1).map((i, index) => {
                                                            return (<div className={`rating ${index < item.rating ? 'active' : ''}`}></div>)
                                                        })
                                                    }
                                                </div>
                                            </>
                                            </div>
                                            <div className="mb40 text-center">
                                                <RichText render={item.text}/>
                                            </div>
                                            <div className="mb40 text-center">
                                                <CustomLink
                                                    link={item.link}
                                                    className={getLinkClass(item.link_style, "link")}>
                                                    {item.link_text}
                                                </CustomLink>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <a className="left carousel-control" href="#testimonials-carousel" role="button" data-slide="prev">
                            <span className="glyphicon glyphicon-chevron-left"/>
                        </a>
                        <a className="right carousel-control" href="#testimonials-carousel" role="button" data-slide="next">
                            <span className="glyphicon glyphicon-chevron-right"/>
                        </a>
                    </div>)
                    default:
                    return (<div className="d-flex flex-wrap">
                        {fields.map((item, i) => {
                            return (
                                <div className="col-xs-12 col-md-6 col-lg-4">
                                    <div className="col-3-image testimonials-col-3-image mb40">
                                        <Preview image={item.image} videoUrl={item.video_url}/>
                                    </div>
                                    <div className="d-flex mb25">
                                        <Image className="client-photo" image={item.client_photo}/>
                                        <div>
                                            <p className="client-name">{item.client_name}</p>
                                            <div className="d-flex">
                                                {
                                                    new Array(5).fill(1).map((i, index) => {
                                                        return (<div
                                                            className={`rating ${index < item.rating ? 'active' : ''}`}></div>)
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb40 text-left">
                                        <RichText render={item.text}/>
                                    </div>
                                    <div className="mb40 text-left">
                                        <CustomLink
                                            link={item.link}
                                            className={getLinkClass(item.link_style, "link")}>
                                            {item.link_text}
                                        </CustomLink>
                                    </div>
                                </div>
                            )
                        })}
                    </div>);
                }})()
                }

            </div>
        </Section>
    )
}



